import React from "react"
import { graphql } from "gatsby"
import { MDBCard, MDBCardBody, MDBCardText, MDBContainer, MDBRow } from "mdb-react-ui-kit"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from '../components/SEO';

const Template = ({
  data,
}) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const image = getImage(frontmatter.hero_image)
  return (
    <>
    <Seo title={frontmatter.title + ' - Blog ARGA'} image={image} />
    <MDBContainer className="mt-5 py-1 text-primary">
      <MDBRow className="d-flex justify-content-center align-items-center">
      <MDBCard className='my-2 px-0' style={{maxWidth:'800px'}}>
        <GatsbyImage className="rounded" image={image} />        
        <MDBCardBody>
          <h1 className="fs-2 text-center">{frontmatter.title}</h1>
          <MDBCardText>
            <small className='text-muted'>{frontmatter.date}</small>
          </MDBCardText>
          <MDBCardText>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </MDBCardText>
          <figcaption className="blockquote-footer mb-0 mt-2 d-flex justify-content-end">
              Por {frontmatter.author} en&nbsp;
              <cite title="Categoría">{frontmatter.type}</cite>
            </figcaption>
        </MDBCardBody>
      </MDBCard>
      </MDBRow>
    </MDBContainer>
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        slug
        title
        author
        status
        type
        hero_image {
          childImageSharp {
            gatsbyImageData(
              width: 1170
              height: 390
            )
          }
        }
      }
    }
  }
`

export default Template